import { notifySuccess, notifyError } from "@/shared/helpers/notify";
import ClipboardJS from "clipboard";

export const copyLink = (content, event) => {
  // alert(content);

  const triggerElement = event.currentTarget;

  const clipboard = new ClipboardJS(triggerElement, {
    text: () => content,
  });

  clipboard.on("success", () => {
    clipboard.destroy();
    notifySuccess("Copiado com sucesso!");
  });

  clipboard.on("error", () => {
    clipboard.destroy();
    notifyError("Erro ao copiar link!");
  });
};

export const formatCheckoutByMac = (payload, urlData, userSlug) => {
  const skuAndQuantity = payload.products
    .map((e) => `${formatSkuToLinkByMac(e.sku)}:${e.quantity}`)
    .join(",");

  return urlData[0] + skuAndQuantity + urlData[1] + `&artist=${userSlug}`;
};

const formatSkuToLinkByMac = (sku) => {
  return sku.replace("SKU", "");
};
