<template>
  <div class="store-p-ct">
    <div class="card-product full-height default-rounded">
      <span
        @click="favoriteClick"
        class="cursor-pointer favorite"
        :class="{ 'bg-white': favoriteInProcess }"
      >
        <q-spinner-hearts v-if="favoriteInProcess" color="dark" size="4em" />
        <q-icon
          v-else
          class="card-icon-actions"
          :name="product.favorite ? 'favorite' : 'favorite_border'"
        />
      </span>
      <span
        v-if="!isOnCheckout(product) && getHasCheckout"
        :class="{ 'bg-white': favoriteInProcess }"
      >
        <q-icon
          name="shopping_bag"
          class="card-icon-actions cursor-pointer add-store"
          @click="addToCheckout(product)"
        >
          <q-tooltip class="bg-grey-8" :offset="[10, 10]">
            Adicionar ao carrinho
          </q-tooltip>
        </q-icon>
      </span>

      <div class="cursor-pointer full-height">
        <div
          class="card-picture default-rounded overflow-hidden cursor-pointer"
        >
          <q-img
            fit="cover"
            width="100%"
            :src="prodImage"
            @error="onErrorImage"
          />
        </div>
        <p class="p-description" v-html="product.name" @click="openProduct" />
      </div>
      <div class="text-center p-price q-mb-md q-mt-lg column">
        <span
          class="color-purple"
          v-if="
            product.price > product.promotion_price && product.promotion_price
          "
        >
          <span class="prod-line" v-html="toMoneyLabel(product.price)" />
          <br />
          <span
            class="prod-promotional"
            v-if="product.promotion_price"
            v-html="toMoneyLabel(product.promotion_price)"
          />
        </span>
        <span
          class="color-purple"
          v-else
          v-html="toMoneyLabel(product.price)"
        />
      </div>
      <q-separator />
      <bar-share
        :title="product.name"
        :link="getSharedLink(product.shared_link)"
        class="q-ma-sm full-height"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import BarShare from "@/modules/main/components/barShare";
import { notifyInfo, notifyBottom } from "@/shared/helpers/notify";
import { ref } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("products"),
  authNamespaced = createNamespacedHelpers("auth"),
  UseAssents = createNamespacedHelpers("assents");

export default {
  name: "ProductItem",

  components: { BarShare },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let imageSt = 1;
    const prodImage = ref(props.product.path),
      favoriteInProcess = ref(false);

    const {
      getCheckoutData,
      getCheckoutDataSubTotal,
      getCheckoutParams,
      getHasCheckout,
    } = useGetters([
      "getCheckoutData",
      "getCheckoutDataSubTotal",
      "getCheckoutParams",
      "getHasCheckout",
    ]);

    const { ImgNotFound } = UseAssents.useGetters(["ImgNotFound"]),
      { userSlug } = authNamespaced.useGetters(["userSlug"]),
      {
        removeProductsFromFavorite,
        setProductsAsFavorite,
        checkoutAction,
      } = useActions([
        "setProductsAsFavorite",
        "removeProductsFromFavorite",
        "checkoutAction",
      ]);

    const verifySubTotal = () => {
      const currentPrice =
        props.product.price > props.product.promotion_price &&
        props.product.promotion_price
          ? props.product.promotion_price
          : props.product.price;

      const nextAmount = currentPrice + getCheckoutDataSubTotal.value;

      // Quando não há regra de subtotal
      if (getCheckoutParams.value.maxPurchaseAmount.value == null) return false;

      return nextAmount >= getCheckoutParams.value.maxPurchaseAmount.value;
    };

    const favoriteClick = () => {
      favoriteInProcess.value = true;
      if (!props.product.favorite)
        setProductsAsFavorite({ prod_ids: [props.product.id]})
          .then(() => notifyInfo("products.product_marked_as_favorite"))
          .finally(() => {});
      else
        removeProductsFromFavorite({
          prod_id: props.product.id,
        })
          .then(() => notifyInfo("products.product_removed_from_favorite"))
          .finally(() => {});
    };

    const onErrorImage = () => {
      if (imageSt === 1) {
        prodImage.value = props.product.full_url;
        imageSt++;
      } else prodImage.value = ImgNotFound;
    };

    const openProduct = () =>
      window.open(getSharedLink(props.product.shared_link), "_blank");

    const getSharedLink = (link) =>
      link.includes("{slug_parceiro}")
        ? link.replace(/{slug_parceiro}/g, userSlug.value)
        : link;

    const addToCheckout = (prod) => {
      console.log(prod);
      if (verifySubTotal()) {
        notifyBottom(
          getCheckoutParams.value.maxPurchaseAmount.label,
          "negative"
        );
        return;
      }

      if (
        getCheckoutParams.value.maxSkuQuantity.value &&
        getCheckoutData.value.length >=
          getCheckoutParams.value.maxSkuQuantity.value
      ) {
        notifyBottom(
          "Só é permitido adicionar a quantidade máxima de " +
            getCheckoutParams.value.maxSkuQuantity.value +
            " produtos diferentes no carrinho.",
          "negative"
        );
        return;
      }

      checkoutAction(prod);
    };

    const isOnCheckout = (prod) =>
      getCheckoutData.value.find((p) => p.id === prod.id);

    return {
      userSlug,
      prodImage,
      getHasCheckout,
      toMoneyLabel,
      getCheckoutData,
      favoriteInProcess,
      isOnCheckout,
      openProduct,
      onErrorImage,
      favoriteClick,
      getSharedLink,
      addToCheckout,
    };
  },
};
</script>

<style lang="scss">
.store-p-ct {
  height: 100%;
  .card-product {
    &:hover {
      .p-description {
        color: #2aa7ff;
      }
    }
    background: #ffffff;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .favorite {
      position: absolute;
      top: 5px;
      right: 15px;
      z-index: 5;
      width: 30px;
      text-align: center;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #cccccc7a;
      border-radius: 18px;
    }
    .add-store {
      position: absolute;
      top: 5px;
      left: 15px;
      z-index: 5;
      width: 30px;
      text-align: center;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #cccccc7a;
      border-radius: 18px;
    }
    .card-picture {
      position: relative;
      text-align: center;
      img {
        max-height: 220px;
      }
      span {
        padding: 8px 10px;
        position: absolute;
        right: 0;
        top: 4px;
        background: #ffffffa6;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        justify-content: center;
      }
    }
    .p-description {
      margin: 9px 0 0;
      color: #28283b;
      text-align: center;
      font-weight: 300;
      transition: color 1s;
      flex: 1;
      padding: 5px;
    }
    .p-price {
      font-size: 13pt;
    }
    .prod-promotional {
      color: red;
    }
    .prod-line {
      text-decoration: line-through;
    }
  }
}
.mobile {
  .store-p-ct {
    .card-product {
      box-shadow: 0px 0px 6px -4px;
      .p-description {
        font-size: 12px;
      }
      .p-price {
        font-size: 14pt;
      }
    }
  }
}

.card-icon-actions {
  font-size: 18pt;
}

@media (max-width: 620px) {
  .card-icon-actions {
    font-size: 25pt;
    //  margin-top: 10px;
  }
}
</style>
