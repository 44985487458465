<template>
  <q-carousel
    animated
    :navigation="banners.length > 1 ? true : false"
    infinite
    :arrows="banners.length > 1 ? true : false"
    swipeable
    height="350px"
    control-type="flat"
    :autoplay="autoplay"
    v-model="slide"
    @mouseenter="autoplay = false"
    @mouseleave="autoplay = true"
  >
    <q-carousel-slide
      v-for="(banner, key) in banners && banners.length
        ? banners
        : defaultCarousel"
      :key="`carousel-banner-${banner.id}`"
      :name="key"
      :img-src="
        $q.platform.is.mobile ? banner.path_mobile : banner.path_desktop
      "
    />
  </q-carousel>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref, reactive } from "vue";

const UseAssents = createNamespacedHelpers("assents");

export default {
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const { bannerLpDefault } = UseAssents.useGetters(["bannerLpDefault"]);

    const defaultCarousel = reactive([
      {
        id: "-999-df",
        path_desktop: bannerLpDefault,
        path_mobile: bannerLpDefault,
      },
    ]);

    const slide = ref(0),
      autoplay = ref(true);

    return {
      slide,
      defaultCarousel,
      autoplay,
    };
  },
};
</script>
