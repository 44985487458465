<template>
  <div
    class="row q-pt-lg q-col-gutter-xs justify-center"
    :class="{
      'justify-center': !$q.platform.is.mobile,
      'justify-between': $q.platform.is.mobile,
    }"
  >
    <div
      class="prod-display"
      v-for="product in products"
      :key="`xs-${product.id}`"
    >
      <product-item :product="product" :key="`product-item-${product.id}`" />
    </div>
  </div>

  <ProductsCheckout v-if="getIsOpenedCheckoutSide" />
</template>

<script>
import ProductsCheckout from "./ProductsCheckout.vue";
import ProductItem from "@/modules/main/pages/products/components/productItem";
import { createNamespacedHelpers } from "vuex-composition-helpers";

const { useGetters } = createNamespacedHelpers("products");

export default {
  name: "Products",

  components: { ProductItem, ProductsCheckout },

  props: {
    products: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const { getCheckoutData, getIsOpenedCheckoutSide } = useGetters([
      "getCheckoutData",
      "getIsOpenedCheckoutSide",
    ]);

    return {
      getCheckoutData,
      getIsOpenedCheckoutSide,
    };
  },
};
</script>

<style lang="scss">
.prod-display {
  width: 100%;
  max-width: 220px;
  margin: 10px 15px;
}
.mobile {
  .prod-display {
    width: 49%;
    margin: 5px 0;
  }
}
</style>
