<template>
  <div class="row q-col-gutter-xs justify-center">
    <div class="col-2 min-width-p q-ma-md" v-for="n in 12" :key="`xs-${n}`">
      <product-skeleton class="q-ma-sm" />
    </div>
  </div>
</template>

<script>
import ProductSkeleton from "@/modules/main/pages/products/components/productSkeleton";

export default {
  name: "ProductsSkeleton",

  components: { ProductSkeleton },
};
</script>

<style scoped lang="scss">
.min-width-p {
  max-width: 250px;
}
.mobile {
  .min-width-p {
    min-width: 250px;
    zoom: 0.5;
  }
}
</style>
