<template>
  <q-card class="bg-white q-mr-auto q-ml-auto" v-if="test">
    <q-img
      fit="cover"
      width="100%"
      class="q-ml-auto q-mr-auto"
      :src="prodImage"
      @error="onErrorImage"
    />
    <p class="p-description" v-html="product.name" />
    <div class="text-center p-price q-mb-md column">
      <span
        class="color-purple"
        v-if="
          product.price > product.promotion_price && product.promotion_price
        "
      >
        <span class="prod-line" v-html="toMoneyLabel(product.price)" />
        <br />
        <span
          class="prod-promotional"
          v-if="product.promotion_price"
          v-html="toMoneyLabel(product.promotion_price)"
        />
      </span>
      <span class="color-purple" v-else v-html="toMoneyLabel(product.price)" />
    </div>
    <div class="text-center p-price q-mb-md column row">
      <q-input
        dense
        filled
        bottom-slots
        v-model="quantity"
        style="width: 150px;"
        :disable="true"
        class="q-mr-auto q-ml-auto"
      >
        <template v-slot:before>
          <q-btn
            round
            dense
            icon="remove"
            color="black"
            :disable="quantity < 0"
            @click="handleQuantity('remove')"
          />
        </template>
        <template v-slot:after>
          <q-btn
            round
            dense
            icon="add"
            color="black"
            @click="handleQuantity('add')"
          />
        </template>
      </q-input>
    </div>
  </q-card>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyBottom } from "@/shared/helpers/notify";
import { ref, watch } from "vue";

const UseAssents = createNamespacedHelpers("assents");
const { useActions, useGetters } = createNamespacedHelpers("products");

export default {
  name: "CheckoutItem",

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  emits: ["updateQuantity"],

  setup(props, { emit }) {
    let imageSt = 1;
    const prodImage = ref(props.product.path);
    const quantity = ref(props.product.quantity);
    const test = ref(true);

    const { ImgNotFound } = UseAssents.useGetters(["ImgNotFound"]);
    const { replaceProductById } = useActions(["replaceProductById"]),
      { getCheckoutParams, getCheckoutDataSubTotal } = useGetters([
        "getCheckoutParams",
        "getCheckoutDataSubTotal",
      ]);

    const onErrorImage = () => {
      if (imageSt === 1) {
        prodImage.value = props.product.full_url;
        imageSt++;
      } else prodImage.value = ImgNotFound;
    };

    const verifySubTotal = () => {
      const currentPrice =
        props.product.price > props.product.promotion_price &&
        props.product.promotion_price
          ? props.product.promotion_price
          : props.product.price;

      const nextAmount = currentPrice + getCheckoutDataSubTotal.value;
      return nextAmount >= getCheckoutParams.value.maxPurchaseAmount.value;
    };

    watch(quantity, async (cv) => {
      const data = {
        ...props.product,
        quantity: cv,
      };

      if (cv == 0) emit("updateQuantity");
      replaceProductById(data);
    });

    const handleQuantity = (type) => {
      if (type == "add") {
        if (
          getCheckoutParams.value.maxTotalItems.value != null &&
          (getCheckoutParams.value.maxTotalItems.value ==
            props.product.quantity ||
            verifySubTotal())
        )
          notifyBottom(
            "Só é permitido adicionar a quantidade máxima de " +
              getCheckoutParams.value.maxTotalItems.value +
              " para cada produto selecionado.",
            "negative"
          );
        else quantity.value++;
      } else {
        quantity.value--;
      }
    };

    return {
      test,
      quantity,
      prodImage,
      toMoneyLabel,
      getCheckoutDataSubTotal,
      getCheckoutParams,
      onErrorImage,
      verifySubTotal,
      handleQuantity,
    };
  },
};
</script>

<style lang="scss" scoped>
.p-description {
  padding: 5px;
  font-size: 9pt;
  color: #28283b;
  text-align: center;
  font-weight: 300;
  transition: color 1s;
  margin: 9px 0 0;
  flex: 1;
}
.prod-line {
  text-decoration: line-through;
}
</style>
