<template>
  <div class="q-pa-md default-rounded bg-white">
    <q-card flat class="bg-white">
      <q-skeleton class="bg-grey-4 min-width-s" height="200px" square />

      <q-card-section class="row justify-center">
        <q-skeleton type="text" width="100%" class="text-subtitle1 bg-grey-4" />
        <q-skeleton
          type="text"
          animation="blink"
          width="50%"
          class="text-subtitle1"
        />
      </q-card-section>
      <q-skeleton type="text" class="text-caption" animation="blink" />
    </q-card>
  </div>
</template>
